@import url(https://fonts.googleapis.com/css?family=Titillium+Web:400,700);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
  height: 100vh;
}

.home {
  height: 100%;
  overflow: auto;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  max-width: 1336px;
  margin: 0 auto;
  -webkit-flex-direction: column;
          flex-direction: column;
}
.Otbtn {
  text-align: center;
  font-size: 20px;
  font-weight: bold;
  cursor: pointer;
  display: -webkit-flex;
  display: flex;
  max-width: 350px;
  min-width: 270px;
  -webkit-justify-content: center;
          justify-content: center;
  margin: 20px 5px 5px 0;
  border-radius: 10px;
  background-color: #FDCF48;
  padding: 15px 25px ;
}
.Otbtn:first-child {
  margin-top: 0;
}
.navBar {
  width: 100%;
  text-align: left;
  padding: 5px 10px;
  cursor: pointer;
  color: #FDCF48;
  font-size: 25px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  background-color: rgba(0, 0, 0, 0.7);
}
body {
  position: relative;
  font-family: "Titillium Web", sans-serif;
  -webkit-font-smoothing: antialiased;
  height: 100%;
  width: 100%;
}
#restartbtn {
  text-align: center;
  color: #fff;
  cursor: pointer;
  margin: 20px;
}
.table {
  display: table;
  height: 100%;
  width: 100%;
  position: relative;
}

.cell {
  display: table-cell;
  vertical-align: middle;
  position: relative;
  width: 100%;
  text-align: center;
}

/*------
# Pincode
----*/

#pincode {
  position: fixed;
  overflow: hidden;
  height: 667px;
  width: 375px;
  z-index: 9999;
  background-image: url("https://s3-us-west-2.amazonaws.com/s.cdpn.io/214624/mountain.jpg");
  background-size: cover;
  background-position: center;
  color: #fff;
  border-radius: 10px;
  text-align: center;
  box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.6);
  top: 50%;
  left: 50%;
  margin-top: -335px;
  /* Negative half of height. */
  margin-left: -188px;
  /* Negative half of width. */
}

#numbers {
  max-width: 300px;
  padding: 0 20px;
  margin: 0 auto;
  position: relative;
  display: block;
  transition: all 1s ease-out;
  opacity: 1;
}

#numbers.hide {
  opacity: 0.3;
}

#pincode button {
  width: 70px;
  height: 70px;
  margin-bottom: 10px;
  background-color: rgba(0, 0, 0, 0.35);
  border: 0;
  color: #fff;
  font-size: 25px;
  line-height: 50px;
  border-radius: 100%;
  opacity: 1;
  outline: 0;
  border: 2px solid #272a2f;
}

#pincode button:active {
  background-color: rgba(0, 0, 0, 0.6);
  outline: 0;
}

#fields {
  max-width: 200px;
  padding: 0 20px;
  margin: 50px auto;
  position: relative;
  display: block;
}

#fields .numberfield {
  text-align: center;
}

#fields .numberfield span {
  height: 10px;
  width: 10px;
  border: 2px solid #fff;
  background-color: transparent;
  border-radius: 100%;
  position: relative;
  display: inline-block;
  text-align: center;
}

#fields .numberfield.active span {
  background-color: #fff;
}

#fields .numberfield.right span {
  background-color: #272a2f;
  border-color: #272a2f;
  transition: all 0.5s ease-in-out;
  -webkit-transition: all 0.5s ease-in-out;
}

/*------
# Toast Grid
----*/

.grid {
  list-style: none;
  margin-left: -20px;
}

.grid__col--1-of-3,
.grid__col--2-of-6,
.grid__col--4-of-12 {
  width: 33.33333%;
}

.grid__col--1-of-4,
.grid__col--2-of-8,
.grid__col--3-of-12 {
  width: 25%;
}

.grid__col {
  box-sizing: border-box;
  display: inline-block;
  margin-right: -0.25em;
  min-height: 1px;
  padding-left: 20px;
  vertical-align: top;
}

.grid__col--centered {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.grid__col--d-first {
  float: left;
}

.grid__col--d-last {
  float: right;
}

.grid--no-gutter {
  margin-left: 0;
  width: 100%;
}

.grid--no-gutter .grid__col {
  padding-left: 0;
}

.grid--no-gutter .grid__col--span-all {
  margin-left: 0;
  width: 100%;
}

.grid__col--ab {
  vertical-align: bottom;
}

.grid__col--am {
  vertical-align: middle;
}

.miss {
  -webkit-animation: miss 0.8s ease-out 1;
  animation: miss 0.8s ease-out 1;
}

@-webkit-keyframes miss {
  0% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
  }
  10% {
    -webkit-transform: translate(-25px, 0);
    transform: translate(-25px, 0);
  }
  20% {
    -webkit-transform: translate(25px, 0);
    transform: translate(25px, 0);
  }
  30% {
    -webkit-transform: translate(-20px, 0);
    transform: translate(-20px, 0);
  }
  40% {
    -webkit-transform: translate(20px, 0);
    transform: translate(20px, 0);
  }
  50% {
    -webkit-transform: translate(-10px, 0);
    transform: translate(-10px, 0);
  }
  60% {
    -webkit-transform: translate(10px, 0);
    transform: translate(10px, 0);
  }
  70% {
    -webkit-transform: translate(-5px, 0);
    transform: translate(-5px, 0);
  }
  80% {
    -webkit-transform: translate(5px, 0);
    transform: translate(5px, 0);
  }
  100% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
  }
}

@keyframes miss {
  0% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
  }
  10% {
    -webkit-transform: translate(-25px, 0);
    transform: translate(-25px, 0);
  }
  20% {
    -webkit-transform: translate(25px, 0);
    transform: translate(25px, 0);
  }
  30% {
    -webkit-transform: translate(-20px, 0);
    transform: translate(-20px, 0);
  }
  40% {
    -webkit-transform: translate(20px, 0);
    transform: translate(20px, 0);
  }
  50% {
    -webkit-transform: translate(-10px, 0);
    transform: translate(-10px, 0);
  }
  60% {
    -webkit-transform: translate(10px, 0);
    transform: translate(10px, 0);
  }
  70% {
    -webkit-transform: translate(-5px, 0);
    transform: translate(-5px, 0);
  }
  80% {
    -webkit-transform: translate(5px, 0);
    transform: translate(5px, 0);
  }
  100% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
  }
}

#hastylink {
  position: fixed;
  bottom: 5px;
  left: 0;
  right: 0;
  font-size: 13px;
  color: #fff;
  text-decoration: none;
  text-align: center;
}

